import Vue from 'vue/dist/vue.common';
import _, { filter } from 'lodash';

export class Members_List {

    constructor(){
        this.vue_element = false;
    }

    init(){
        this.vue_element = new Vue({
            el: '#members__root',
            template: '#members__root-template',
            data() {
                return {
                    result: false,
                    groups: [],
                    members: [],
                    responseAvailable: false,
                    listStyleBig: true,
                    searchTerm: "",
                    selectedTab: 0,
                    modalActive: false,
                    modalInfo: false,
                };
            },
            created() {
              this.fetchMembersData();
              window.addEventListener('keyup', this.handleKeyUp );
            },
            methods: {
              fetchMembersData( ) {
                this.responseAvailable = false;

                fetch("/wp-json/freunde/v1/groups/mine", {
                    "method": "GET",
                    "headers": {
                        'X-WP-Nonce': cbfreunde.nonce
                    }
                })
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }
                    throw new Error('no auth');
                })
                .then(response => {
                    this.result = response;
                    this.responseAvailable = true;
                    let sortedGroups = []; 
                    for (let i = 0; i < this.result.length; i++) {
                        let group = _.cloneDeep(this.result[i]);
                        let sortedMembers = _.cloneDeep(group.groupMembers);
                        sortedMembers = _.sortBy(sortedMembers, ['memberName']);
                        group.groupMembers = sortedMembers;
                        sortedGroups.push(group);
                    }
                    this.groups = sortedGroups;
                })
                .catch(err => {
                    //console.log(err);
                });
              },

              switch_style: function(style) {
                if(style == 'raster'){
                  this.listStyleBig = true;
                } else {
                  this.listStyleBig = false;
                }
              },

              open_modal: function(member){
                //console.log(member);
                this.modalInfo = member;
                this.modalActive = true;
              },

              close_modal: function() {
                this.modalInfo = false;
                this.modalActive = false;
              },

              switch_tab: function(index){
                this.selectedTab = index;
              },

              throttled_setSearchText:_.debounce( function(value){
                  this.searchTerm = value;
                }, 500, { 'maxWait': 1000 }
              ),

              update_search: function(event){
                this.throttled_setSearchText(event.target.value);
              },

              handleKeyUp(e) {
                if (e.keyCode === 27) {
                  this.close_modal();
                }
              },
            },

            filters: {
              br2nl(value) {
                if (value) {
                  //console.log(value);
                  return value.replaceAll('<br />', '\n');
                }
                return value;
              }
            },

            computed: {
              filteredGroups() {
                let filteredGroups = [];
                for (let i = 0; i < this.groups.length; i++) {
                    let group = _.cloneDeep(this.groups[i]);
                    if(this.searchTerm !== ''){
                        let filteredMembers = group.groupMembers.filter(member => {
                            return member.memberName.toLowerCase().includes(this.searchTerm.toLowerCase());
                        });
                        group.groupMembers = filteredMembers;
                    }
                    filteredGroups.push(group);
                }
                return filteredGroups;
              }
            }
          })
;
        window.membersList = this.vue_element;
    }
}