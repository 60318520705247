import $ from 'jquery';
import _ from 'lodash';
import objectFitImages from 'object-fit-images';
import modal from 'jquery-modal';
import { slick, unslick } from 'slick-carousel';
import Player from '@vimeo/player';
//import StickySidebar from 'sticky-sidebar';

var activeFilters = [];
var lastScrollY = 0;

function onYouTubeIframeAPIReady() {
  const youtube_players = document.querySelectorAll( ".yt_player" );

  for (var i = 0; i < youtube_players.length; i++) {
      youtube_players[i].addEventListener( "click", function(event) {
          event.preventDefault();
          event.stopPropagation();
          new YT.Player(this, {
              height: '360',
              width: '640',
              videoId: this.dataset.ytid,
              playerVars: {
                  'modestbranding': 1,
              },
              events: {
                  'onReady': function(event){
                      event.target.playVideo();
                  },
              }
          });
      } );
  }
}
window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

export default {

  isInViewport (element_id) {
    let elem = document.getElementById(element_id);
    let bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  },

  calculatePosition(pos){
    var slideWidth = $('.slide').width() + 16; // 16 = margin
    return (pos - 1) * slideWidth * -1;
  },

  updateSlidePosition(forcePos = false, loop = false){
    var $container = $('.slider');
    var totalSlides = $container.data('slides');
    var currentSlide = $container.data('slide');
    if(forcePos !== false){
      currentSlide = forcePos;
      $container.data('slide', parseInt(forcePos));
    }
    var slidePosPixel = this.calculatePosition(currentSlide);

    $container.animate( { marginLeft: slidePosPixel }, 500, 'swing', () => {
      if(loop){
        var slidePosPixel = this.calculatePosition(loop);
        var slidePosCSS = slidePosPixel + 'px';
        $container.data('slide', parseInt(loop));
        $container.css('marginLeft', slidePosCSS);
        this.updatePaginationPosition(loop);
      } else {
        this.updatePaginationPosition(currentSlide);
      }

    });
  },

  updatePaginationPosition(pos){
    $('.slider-dot').removeClass('active');
    $('.slide-' + pos).addClass('active');
  },

  move(direction) {
    var $container = $('.slider');
    var totalSlides = $container.data('slides');
    var currentSlide = $container.data('slide');

    if(direction == 'left' && currentSlide > 1){
      this.updateSlidePosition(currentSlide - 1);
    } else if(direction == 'right' && currentSlide < totalSlides) {
      this.updateSlidePosition(currentSlide + 1);
    } else if(direction == 'left' && currentSlide <= 1){
      this.updateSlidePosition(currentSlide - 1, totalSlides);
    } else if(direction == 'right' && currentSlide >= totalSlides){
      this.updateSlidePosition(currentSlide + 1, 1);
    }

  },

  updateFilter() {
    $('.event-active-filters').empty();

    if(activeFilters.length){
      $('.event-filter-top').addClass('active');

      for(let activeFilter of activeFilters ){
        $('.event-active-filters').append('<li data-tag-type="' + activeFilter.type + '" data-tag-id="' + activeFilter.id + '" data-tag-name="' + activeFilter.name + '">' + activeFilter.name + ' <span data-tag-type="' + activeFilter.type + '" data-tag-id="' + activeFilter.id + '" data-tag-name="' + activeFilter.name + '" class="event-filter-clear">✕</span></li>');
      }
      $('.list-item').hide();

      let filterString = "";
      for(let activeFilter of activeFilters ){
        filterString += '.' + activeFilter.itemClass;
      }
      $(filterString).show();

    } else {
      $('.event-filter-top').removeClass('active');
      $('.list-item').show();
    }


  },

  removeFromFilter(filterObject) {
    _.remove(activeFilters, function(currentObject) {
      return currentObject.id == filterObject.id && currentObject.type == filterObject.type;
    });
    this.updateFilter();
  },

  clearFilter(){
    activeFilters = [];
    this.updateFilter();
  },

  addToFilter(filterObject) {
    activeFilters = _.uniqBy(_.concat(activeFilters, filterObject), 'compoundId');
    this.updateFilter();
  },

  getAllFilterElements(){
    var eventCats = []
    $('.list-iteam').each(function(){

    });
  },

  init() {

    var checkHeader = _.throttle(() => {
      let scrollPosition = Math.round(window.scrollY);      

      if (scrollPosition > 0){
        $('.header-container').addClass('sticky');
        let scrollBottom = $('body').height() - $(window).height() - window.scrollY;
       /* if(scrollBottom < 150){
          $('#sidebar').addClass('sticky');
        } else {
          $('#sidebar').removeClass('sticky');
        }*/
      } else {
        $('.header-container').removeClass('sticky');
      }
     /* if(this.isInViewport('sidebar-marker') && (lastScrollY < scrollPosition) ){
        console.log('in');
        $('#sidebar').addClass('in-view');
      }
      if(this.isInViewport('sidebar-marker') && (lastScrollY > scrollPosition) ){
        console.log('out');
        $('#sidebar').removeClass('in-view');
      }*/
      
      /*else {
        console.log('out');
        $('#sidebar').removeClass('in-view');
      }*/
      /*if(this.isInViewport('event-header')){
        $('#sidebar').removeClass('in-view');
        console.log('out');
      }*/
      lastScrollY = scrollPosition;

    }, 200, { 'leading': true });

    window.addEventListener('scroll', checkHeader);

    var debounceResize = _.debounce(function(){
      var $container = $('.slider');
      var currentSlide = $container.data('slide');
      var slideWidth = $('.slide').width() + 16; // 16 = margin
      var slidePosPixel = (currentSlide - 1) * slideWidth * -1;
      $container.animate( { marginLeft: slidePosPixel }, 100, 'swing');
      $('.main-left').css('height', $('.main-right').css('height'));
    }, 100)

    $(window).on('resize', debounceResize);

    $('.main-left').css('height', $('.main-right').css('height'));

    $('.menu-icon').on('click', function(){
      $('.header').toggleClass('menu-show');
      $('.menu-container-overlay').toggleClass('active');
    });

    $('.slider-dot').on('click', (event) => this.updateSlidePosition($(event.currentTarget).data('slide')));

    setInterval( () => !document.hidden && this.move('right'), 5000);

    $(function () {
      objectFitImages();
      if (!navigator.cookieEnabled){
        $('.first-time-visit').removeClass('active');
      }
    });

    $('.image-credits-headline').on('click', function(){
      $('.image-credits-headline').parent().toggleClass('active');
    });

    $('.list-item-opener').on('click', function(){
      $(this).parent().parent().toggleClass('active');
    });

    $('.event-register').on('click', function(){
      var title = $(this).data('eventtitle');
      var cats = $(this).data('eventcats');
      var event_id = $(this).data('eventid');
      var event_date_time = $(this).data('eventdatetime');
      //console.log(cats);
      $('#register-event input[name="event"]').val(title);
      $('#register-event input[name="event_kategorien"]').val(cats);
      $('#register-event input[name="event_id"]').val(event_id);
      $('#register-event input[name="event_date_time"]').val(event_date_time);
    });


    //event filter
    $('.event-filter-title').on('click', function(){
      if($(this).parent().hasClass('active')){
        $('.event-filter-menu').removeClass('active');
      } else {
        $('.event-filter-menu').removeClass('active');
        $(this).parent().addClass('active');
      }
    });

    $('.event-filter-title-mobile').on('click', function(){
      if( $('.event-filter-top-mobile').hasClass('active') ){
        $('.event-filter-top-mobile').removeClass('active');
        $('.event-filter-bottom').removeClass('active');
        $('.event-filter-menu').removeClass('active');
      } else {
        $('.event-filter-top-mobile').addClass('active');
        $('.event-filter-bottom').addClass('active');
      }
    });

    $('.event-active-filters').on('click', '.event-filter-clear', (event) => {this.removeFromFilter(
        {
          id: $(event.currentTarget).data('tag-id'),
          name: $(event.currentTarget).data('tag-name'),
          type: $(event.currentTarget).data('tag-type'),
          compoundId: $(event.currentTarget).data('tag-type') + $(event.currentTarget).data('tag-id'),
          itemClass: $(event.currentTarget).data('tag-itemclass'),
        }
      )}
    );

    $('.event-filter-clear-all').on('click', () => this.clearFilter());

    $('.event-filter-menu ul li').on('click', (event) => {this.addToFilter(
        {
          id: $(event.currentTarget).data('id'),
          name: $(event.currentTarget).data('name'),
          type: $(event.currentTarget).data('type'),
          compoundId: $(event.currentTarget).data('type') + $(event.currentTarget).data('id'),
          itemClass: $(event.currentTarget).data('itemclass'),
        }
      )}
    );

    this.updateFilter();
    
    /*var sidebar = new StickySidebar('#sidebar-left', {
      containerSelector: '.main',
      //innerWrapperSelector: '.sidebar__inner',
      topSpacing: 400,
      bottomSpacing: 360
    });*/

    $('.close-first').on('click', function(event){
      event.preventDefault();
      $('.first-time-visit').removeClass('active');
      return false;
    });

    $('body').on('wpcf7mailsent', function(event){ 
      console.log(event.detail.apiResponse.message);
      $('#register-response').append('<p>' + event.detail.apiResponse.message + '</p>').modal();  
    });

    $('.login-modal').on('click', function(event) {
      event.preventDefault();
      $('.menu-container-overlay').removeClass('active');
      $('#login-form').modal();
      return false;
    });

    const youtube_players = document.querySelectorAll( ".yt_player" );
    
    if(youtube_players.length > 0) {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    var vimeo_players = document.querySelectorAll( ".vimeo_player" );

    for (var i = 0; i < vimeo_players.length; i++) {
        vimeo_players[i].addEventListener( "click", function(event) {
            event.preventDefault();
            event.stopPropagation();
            let this_player_element = this;

            let this_player = new Player(this, {
                id: this.dataset.vimeoid,
                byline: false,
                title: false,
            });

            this_player_element.querySelector('.arrow-wrap').classList.add('hide');
            //this_player_element.querySelector('.loader').classList.add('show');

            this_player.on('loaded', function(){
                //remove play button & cover image
                //this_player_element.querySelector('.loader').classList.add('hide');
                this_player_element.querySelector('.vimeo_thumb').classList.add('hide');
                this.play();
            })
        } );
    } 

    $('.cb-gallery').slick({
      dots: false,
      speed: 500,
      prevArrow: '<div class="prevArea"><button type="button" class="slick-prev">← vorheriges Bild</button></div>',
      nextArrow: '<div class="nextArea"><button type="button" class="slick-next">nächstes Bild →</button></div>',
    });

    $('.gallery__close').on('click', function(){
      $('.gallery__modal').removeClass('active');      
    });

    $('.gallery_front').on('click', function(){
      $('#modal-' + $(this).data('gid')).addClass('active');
      /*$('#modal-' + $(this).data('gid') + ' .cb-gallery').slick({
        dots: false,
        speed: 500,
        prevArrow: '<div class="prevArea"><button type="button" class="slick-prev">← vorheriges Bild</button></div>',
        nextArrow: '<div class="nextArea"><button type="button" class="slick-next">nächstes Bild →</button></div>',
      });*/
      $('#modal-' + $(this).data('gid') + ' .cb-gallery').slick('refresh');
    });

    $('.close__search').on('click', function(){
      $('.searchbar__overlay').removeClass('active');      
    });

    $('.search-icon').on('click', function(){
      $('.searchbar__overlay').addClass('active');
      $('.search-field').focus();
    });

    $('.event__moreitems').on('click', function(){
      $('.event__item').removeClass('overflow');
      $(this).addClass('hide');
    });

  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
